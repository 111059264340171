import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSVReader } from 'react-papaparse';
import LoadingOverlay from 'react-loading-overlay';
import { common } from '../lib/Common';
import Remote from '../lib/Remote';
import { useSelector } from 'react-redux';
import dids from '../resources/did-output.json';

const DEFAULT_PLAN_ID = '6164a44e4d9271674a01a193';

const CUSTOMER_DATA = {
    company_name: '',
    domain_name: '',
    account_name: '',
    address1: '',
    address2: '',
    city: '',
    province: 6,
    postal_code: '',
    plan_id: DEFAULT_PLAN_ID,
    npa: null,
    nxx: null,
    region_index: 0,
};

const PROVINCES = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan'
]

const REQ_FIELDS = [
    'company_name',
    'domain_name',
    'account_name',
    'plan_id',
    'region_index',
]

const ADDRESS_KEYS = [
    'address1',
    'address2',
    'city',
    'province',
    'postal_code',
];

const buttonRef = React.createRef();

const fileInputRef = React.createRef();

function AddCustomer() {
    const [customer, setCustomer] = useState({ ...CUSTOMER_DATA });

    const [extensions, setExtensions] = useState(null);

    const [showOverlay, setShowOverlay] = useState(false);

    const [overlayText, setOverlayText] = useState('Connecting...');

    const tokenData = useSelector((state) => state.auth.tokenData);

    const dispatch = useDispatch();

    const history = useHistory();

    const remote = new Remote(dispatch, history);

    const updateField = (fieldName, value) => {
        let cust = { ...customer }

        if (cust.hasOwnProperty(fieldName)) {
            cust[fieldName] = value;

            setCustomer(cust);
        }
    }

    const addCustomer = () => {
        if (!_isDataValid()) {
            common.notification("Data Entry Error", _renderRequiredFields(), dispatch);
            return;
        }

        let clientData = {...customer};
        let sortedDids = _sortDids();
        
        for (const key of Object.keys(clientData)) {
            if (clientData[key] === '') {
                delete clientData[key];
            }

            if (ADDRESS_KEYS.includes(key) && (key !== 'address1' && key !== 'province')) {
                if (!clientData.hasOwnProperty('address1') && clientData.hasOwnProperty(key) && clientData[key] !== '') {
                    common.notification('Error', 'Please supply a first line for the customer address', dispatch);
                    return;
                }
            }
        }

        if (!clientData.hasOwnProperty('address1')) {
            delete clientData.province;
        } else {
            clientData.province = PROVINCES[clientData.province];
        }

        clientData.npa = sortedDids[clientData.region_index].npa;
        clientData.nxx = sortedDids[clientData.region_index].nxx;

        setShowOverlay(true);
        setOverlayText('Adding customer...');

        remote.addCustomer(clientData, tokenData).then((client) => {
            setOverlayText('Adding extensions...');

            remote.addExtensions(client.id, extensions, tokenData).then((exts) => {
                setOverlayText('Onboarding customer...');

                remote.onboard(client.id, tokenData).then((added) => {
                    if (added) {
                        common.notification(
                            "Customer Added",
                            `${client.company_name} has been successfully onboarded with ${exts.length || 0} extensions.`,
                            dispatch
                        );

                        clearCustomer();
                    } else {
                        common.notification('Error', `${client.company_name} has been NOT been onboarded.`, dispatch);
                    }
                });
            });
        }).catch((error) => {
            common.notification("Error", error.message, dispatch);
        }).finally(() => {
            setShowOverlay(false);
        });
    }

    const clearCustomer = () => {
        setCustomer({ ...CUSTOMER_DATA });

        buttonRef.current.removeFile();

        setExtensions(null);
    }

    const handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e);
        }
    }

    const handleOnFileLoad = (data) => {
        let keys = [];
        let extensions = [];

        for (let i = 0; i < data.length; i++) {
            if (i === 0) {
                keys = [...data[i].data];
            } else {
                let ext = {};

                for (let j = 0; j < keys.length; j++) {
                    ext[keys[j]] = data[i].data[j];
                }

                extensions.push(ext);
            }
        }

        setExtensions(extensions);
    }

    const handleOnError = (err, file, inputElem, reason) => {
        console.error(reason);
    }

    const _isDataValid = () => {
        for (const field of REQ_FIELDS) {
            if (customer[field] === '') {
                return false;
            }
        }

        if (extensions === null) {
            return false;
        }

        return true;
    }

    const _sortDids = () => {
        let selections = [...dids];

        return selections.sort((a, b) => {
            if (a.rc === b.rc) {
                return 0;
            }

            return a.rc < b.rc ? -1 : 1;
        });
    }

    const _renderFileName = (file) => {
        if (file && file.name) {
            return <span id="ext-file-name" ref={fileInputRef}>{file.name || ''}</span>;
        }

        return null;
    }

    const _renderRequiredFields = () => {
        return (
            <div>
                Please provide all required fields:
                <ul>
                    {REQ_FIELDS.map((fieldName, i) => {
                        return <li key={`req-${fieldName}`}>{_formatFieldName(fieldName)}</li>;
                    })}
                    <li>Extensions</li>
                </ul>
            </div>
        );
    }

    const _formatFieldName = (fieldName) => {
        let formatted = fieldName;

        if (fieldName.indexOf('_')) {
            formatted = fieldName.replaceAll('_', ' ');
        }

        return common.capitalize(formatted);
    }

    return (
        <section id="contact" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                        <LoadingOverlay
                            active={showOverlay}
                            spinner
                            text={overlayText}
                            className='overlay'
                        >
                            <form name="add-customer" className="contact-form" noValidate>
                                <h3>Add Customer</h3>
                                <a href="/newt_onboarding_extensions.csv">Download Extensions CSV Template</a>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Company name"
                                                    id="company-name"
                                                    data-validation-required-message="Please enter your company name."
                                                    value={customer.company_name}
                                                    onInput={(e) => updateField('company_name', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Domain name"
                                                    id="domain-name"
                                                    required
                                                    data-validation-required-message="Please enter your domain name."
                                                    value={customer.domain_name}
                                                    onInput={(e) => updateField('domain_name', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Account name"
                                                    id="account-name"
                                                    required
                                                    data-validation-required-message="Please enter your account name."
                                                    value={customer.account_name}
                                                    onInput={(e) => updateField('account_name', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Address line 1"
                                                    id="address-1"
                                                    value={customer.address1}
                                                    onInput={(e) => updateField('address1', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Address line 2"
                                                    id="address-2"
                                                    value={customer.address2}
                                                    onInput={(e) => updateField('address2', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="City"
                                                    id="city"
                                                    value={customer.city}
                                                    onInput={(e) => updateField('city', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                            <select
                                                    id="province"
                                                    className="form-control-dropdown"
                                                    ref={fileInputRef}
                                                    value={customer.province}
                                                    onChange={(e) => updateField('province', e.target.value)}
                                                >
                                                    {PROVINCES.map((province, i) => {
                                                        return (
                                                            <option
                                                                key={`row-${i}`}
                                                                value={i}>{province}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Postal code"
                                                    id="postal-code"
                                                    value={customer.postal_code}
                                                    onInput={(e) => updateField('postal_code', e.target.value)}
                                                />
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <select
                                                    id="cities"
                                                    className="form-control-dropdown"
                                                    ref={fileInputRef}
                                                    value={customer.region_index}
                                                    onChange={(e) => updateField('region_index', e.target.value)}
                                                >
                                                    {_sortDids().map((did, i) => {
                                                        return (
                                                            <option
                                                                key={`row-${i}`}
                                                                value={i}>{did.rc}, {did.region}, {did.country} ({did.npa || 'N/A'}/{did.nxx || 'N/A'})
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row control-group">
                                            <div className="form-group col-xs-12 controls">
                                                <CSVReader
                                                    ref={buttonRef}
                                                    onFileLoad={handleOnFileLoad}
                                                    onError={handleOnError}
                                                    noClick
                                                    noDrag
                                                >
                                                    {({ file = null }) => (
                                                        <>
                                                            <button
                                                                type='button'
                                                                className="btn btn-white btn-lg"
                                                                onClick={handleOpenDialog}
                                                            >
                                                                Extensions
                                                            </button>
                                                            &nbsp;
                                                            {_renderFileName(file)}
                                                        </>
                                                    )}
                                                </CSVReader>
                                                <p className="help-block"></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div id="success"></div>
                                <div className="row">
                                    <div className="form-group col-xs-12 text-right">
                                        <button
                                            type="button"
                                            id="clear-customer-button"
                                            className="btn btn-white btn-lg"
                                            onClick={() => clearCustomer()}
                                        >
                                            Clear
                                        </button>&nbsp;
                                        <button
                                            type="button"
                                            id="add-customer-button"
                                            className="btn btn-white btn-lg"
                                            onClick={() => addCustomer()}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </LoadingOverlay>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AddCustomer;
