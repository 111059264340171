import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    tokenData: null,
  },
  reducers: {
    login: (state) => {
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.isLoggedIn = false;
    },
    setTokenData: (state, action) => {
      state.tokenData = {
        token: action.payload.access_token,
        expires: Date.now() + (parseInt(action.payload.expires_in, 10) / 2 * 1000),
      };
    }
  },
})

// Action creators are generated for each case reducer function
export const { login, logout, setTokenData } = authSlice.actions;

export default authSlice.reducer;