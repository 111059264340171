import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "./context/Auth";

// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { isLoggedIn } = useAuthContext();

    return (
        <Route
            {...rest}
            render={(props) => {
                if (isLoggedIn) {
                    return (
                        <>
                            <Component />
                        </>
                    );
                }
                return (
                    // eslint-disable-next-line react/prop-types
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                );
            }}
        />
    );
};

export default ProtectedRoute;