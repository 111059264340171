import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router';
import { useAuthContext } from "../context/Auth";
import { logout } from "../reducers/auth";
import Remote from "../lib/Remote";
import { common } from "../lib/Common";

function NavBar() {
    const dispatch = useDispatch();

    const history = useHistory();

    const { isLoggedIn } = useAuthContext();

    const tokenData = useSelector((state) => state.auth.tokenData);

    const remote = new Remote(dispatch, history);

    const logoutUser = () => {
        remote.logout(tokenData).then((isLoggedOut) => {
            if (!isLoggedOut) {
                throw 'Unable to log out';
            }

            dispatch(logout());
        }).catch((error) => {
            common.notification('Logout Error', error.message, dispatch);
        }).finally(() => {
            history.push('/');
        });
    }

    const renderHelp = () => {
        if (isLoggedIn) {
            return (
                <ul className="nav navbar-nav navbar-right scroll-to">
                    <li><Link to="/customer/list">Customers</Link></li>
                    <li><a href="#" onClick={() => logoutUser()}>Logout</a></li>
                </ul>
            );
        }

        return null;
    }

    return (
        <nav className="navbar navbar-default navbar-fixed-top before-color">
            <div className="container" style={{flex: 1, flexDirection: 'column'}}>
                <div className="navbar-header">
                    <Link to="/"><img src="/fibernetics-logo.png" width="25%" /></Link>
                </div>
                <div id="navbar" className="navbar-collapse collapse" style={{position: 'ab'}}>
                    {renderHelp()}
                </div>
            </div>
        </nav>
    );
}

export default NavBar;