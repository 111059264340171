import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login as userLogin } from '../reducers/auth';
import { common } from '../lib/Common';
import Remote from '../lib/Remote';
import { setTokenData } from '../reducers/auth';

function Login() {
  const [username, setUsername] = useState('');

  const [password, setPassword] = useState('');

  const history = useHistory();

  const dispatch = useDispatch();

  const remote = new Remote(dispatch, history);

  const login = () => {
    if ((username === null || username === '') || (password === null || password === '')) {
      common.notification("Login Error", "Invalid credentials.", dispatch);

      return;
    }

    remote.login(username, password).then((tokenData) => {
      if (tokenData === null) {
        common.notification("Invalid Credentials", 'Error logining in.  Please check your credentials.', dispatch);
      } else {
        dispatch(setTokenData(tokenData));
        dispatch(userLogin(tokenData));

        history.push('/customer/list');
      }
    }).catch((error) => {
      console.error(error.message);
    });
  }

  return (
    <section id="login" className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-sm-offset-3">

            <form name="login" className="contact-form" noValidate>
              <h3>Login</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="row control-group">
                    <div className="form-group col-xs-12 controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="email"
                        required
                        data-validation-required-message="Please enter your email."
                        onInput={(e) => setUsername(e.target.value)}
                      />
                      <p className="help-block"></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row control-group">
                    <div className="form-group col-xs-12 controls">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        id="password"
                        required
                        data-validation-required-message="Please enter your password."
                        onInput={(e) => setPassword(e.target.value)}
                      />
                      <p className="help-block"></p>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div id="success"></div>
              <div className="row">
                <div className="form-group col-xs-12 text-right">
                  <button type="button" className="btn btn-white btn-lg" onClick={() => login()}>Login</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;