import {updateModal} from '../reducers/modal';

class Common {
    isTokenValid(tokenData) {
        if (tokenData !== null && tokenData.token !== null) {
            if (tokenData.expires >= Date.now()) {
                return true;
            }
        }

        return false;
    }

    isExtensionRequired(tokenData) {
        if (this.isTokenValid(tokenData)) {
            if (tokenData.expires <= Date.now()) {
                return true;
            }
        }

        return false;
    }

    notification(title, body, dispatch) {
        dispatch(updateModal({
            title: title,
            body: body,
        }));

        $('#application-modal').modal('show');
    }

    capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

export let common = new Common();