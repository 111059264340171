import { common } from './Common';
import { setTokenData } from '../reducers/auth';

export default class Remote {
    constructor(dispatch, history) {
        this.dispatch = dispatch;
        this.history = history;
        this.url = process.env.REACT_APP_API_URL;
    }

    async login(email, password) {
        let json = null;
        const result = await fetch(`${this.url}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        });

        if (result.status === this._getValidHttpStatusCode()) {
            json = await result.json();
        }
       
        return json;
    }

    async logout(tokenData) {
        // let data = this._getRequestData('POST', null);
        // const result = await this._doAuthenticatedRequest(`${this.url}/auth/logout`, data, tokenData);
        
        // if (result !== null && result.status === this._getValidHttpStatusCode()) {
            return true;
        // }

        // return false;
    }

    async listCustomers(tokenData) {
        let customers = [];
        const data = this._getRequestData('GET', null);
        const result = await this._doAuthenticatedRequest(`${this.url}/v1/clients`, data, tokenData);
        
        if (result !== null && result.status === this._getValidHttpStatusCode()) {
            customers = await this._getResponseData(result);
        } else {
            throw result.statusText;
        }

        return customers;
    }

    async addCustomer(customer, tokenData) {
        let client = null;
        const data = this._getRequestData('POST', customer, true);
        const result = await this._doAuthenticatedRequest(`${this.url}/v1/clients`, data, tokenData);

        if (result !== null && result.status === this._getValidHttpStatusCode(201)) {
            client = await this._getResponseData(result);
        } else {
            throw result.statusText;
        }

        return client;
    }

    async addExtensions(id, extensions, tokenData) {
        let addedExtensions = [];
        const data = this._getRequestData('POST', extensions, true);
        const result = await this._doAuthenticatedRequest(`${this.url}/v1/clients/${id}/extensions`, data, tokenData);

        if (result !== null && result.status === this._getValidHttpStatusCode(201)) {
            addedExtensions = await this._getResponseData(result);
        } else {
            throw result.statusText;
        }


        return addedExtensions;
    }

    async onboard(id, tokenData) {
        let added = false;
        const data = this._getRequestData('POST', null);
        const result = await this._doAuthenticatedRequest(`${this.url}/onboard/${id}`, data, tokenData);

        if (result !== null && result.status === this._getValidHttpStatusCode()) {
            added = true;
        }

        return added;
    }

    async _doAuthenticatedRequest(url, data, tokenData) {
        if (!common.isTokenValid(tokenData)) {
            this.history.push('/');

            throw 'Please login again';
        }

        data.headers['Authorization'] = `Bearer ${tokenData.token}`;

        const response = await fetch(url, data);

        if (common.isExtensionRequired(tokenData)) {
            const tokenData = await this._getExtension(tokenData);

            this.dispatch(setTokenData(tokenData))
        }

        return response;
    }

    async _getExtension(tokenData) {
        const result = await fetch(`${this.url}/auth/refresh`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${tokenData.token}`,
            },
        });

        if (result.status === this._getValidHttpStatusCode()) {
            return await result.json();
        }

        throw 'Unable to extend session with server.';
    }

    _getRequestData(method, body, dataRequest = false) {
        let payload = null;
        const requestData = {
            method: method,
        };

        if (body !== null) {
            if (dataRequest) {
                payload = {
                    data: body
                };
            } else {
                payload = body;
            }

            requestData.body = JSON.stringify(payload);
        }

        return requestData;
    }

    async _getResponseData(response) {
        const json = await response.json();

        return json.data;
    }

    _getValidHttpStatusCode(ok = 200) {
        return (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 501 : ok;
    }
}
