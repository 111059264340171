import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { AuthProvider } from './context/Auth';
import ProtectedRoute from "./ProtectedRoute";
import Login from './pages/Login';
import ListCustomers from './pages/ListCustomers';
import AddCustomer from './pages/AddCustomer';
import NavBar from './components/Navbar';
import './App.css';
import Modal from './components/Modal';
import { useSelector } from 'react-redux';

function App() {
  const {title, body} = useSelector((state) => {
    return {
      title: state.modal.title,
      body: state.modal.body,
    }
  });

  return (
    <>
      <AuthProvider>
        <Router>
          <NavBar />
          <Switch>
            <ProtectedRoute
              exact
              path="/customer/list"
              component={ListCustomers}
            />
            <ProtectedRoute
              exact
              path="/customer/add"
              component={AddCustomer}
            />
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      </AuthProvider>
      <Modal title={title} body={body} />
      <footer className="footer">
        <div className="container text-center">
          <br />
          <span className="copyright"><br />© Copyright 2004 - 2021 • Fibernetics Corporation • All Rights Reserved</span>
        </div>
      </footer>
    </>
  );
}

export default App;
