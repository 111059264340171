import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    title: '',
    body: '',
  },
  reducers: {
    updateModal: (state, action) => {
      state.title = action.payload.title;
      state.body = action.payload.body;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateModal } = modalSlice.actions;

export default modalSlice.reducer;