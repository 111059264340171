import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { common } from '../lib/Common';
import Remote from '../lib/Remote';

function ListCustomers() {
    const [customers, setCustomers] = useState([]);

    const tokenData = useSelector((state) => state.auth.tokenData);

    const dispatch = useDispatch();

    const history = useHistory();

    const remote = new Remote(dispatch, history);

    const renderCustomers = () => {
        if (customers.length === 0) {
            return <p>There are no customers in the database.</p>
        }

        return (
            <table className="table table-dark">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Company</th>
                        <th scope="col">Domain</th>
                        <th scope="col">Account</th>
                        <th scope="col">Realm</th>
                        <th scope="col">Ext.</th>
                        <th scope="col">Provisioned?</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer, i) => {
                        return (
                            <tr key={`row-${i}`}>
                                <th scope="row">{customer.company_name}</th>
                                <td>{customer.domain_name}</td>
                                <td>{customer.account_name}</td>
                                <td>{customer.realm}</td>
                                <td>{customer.extension_count}</td>
                                <td>{customer.provision_pbx ? 'Yes' : 'No'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    useEffect(() => {
        remote.listCustomers(tokenData).then((custs) => {
            setCustomers(custs);
        }).catch((error) => {
            setCustomers([]);

            common.notification('Error', 'Error retrieving customers.', dispatch);
        });
    }, []);


    return (
        <section id="contact" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                        <div className="text-right">
                            <button 
                                type="button" 
                                id="add-customer-button" 
                                className="btn btn-white btn-lg" 
                                onClick={() => history.push('/customer/add')}
                            >
                                Add Customer
                            </button>
                        </div>
                        <br />
                        {renderCustomers()}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ListCustomers;